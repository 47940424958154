const masterChecks = {
  connectivity: {
    name: "connectivity",
    status: "running",
  },
  "check request": {
    name: "check request",
    status: "waiting",
  },
  "master status": {
    name: "master status",
    status: "waiting",
  },
  "notification triggered": {
    name: "notification triggered",
    status: "waiting",
  },
  "notification received In-App": {
    name: "notification received In-App",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "notification received WhatsApp": {
    name: "notification received WhatsApp",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  },
  "ticket raised": {
    name: "ticket raised",
    status: "waiting",
  },
  "flush request": {
    name: "flush request",
    status: "waiting",
  },
  "final flush": {
    name: "final flush",
    status: "waiting",
  },
  "ticket closed": {
    name: "ticket closed",
    status: "waiting",
  },
};

export function getMasterTemplate() {
  return masterChecks;
}
