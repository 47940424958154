import { useState } from "react";
import AddOrUpdateDeviceForm from "./forms/AddOrUpdateDevice";
import AddPartsForm from "./forms/AddParts";
import InventoryList from "./InventoryList";

const tabs = ["inventory devices", "inventory part details", "inventory parts"];

export default function Inventory() {
  const [currentTab, setCurrentTab] = useState(tabs[0]);

  return (
    <div className="pt-20 w-full bg-sky-400">
      <div className="w-full">
        <div className="flex justify-between w-1/2">
          {tabs.map((tab, i) => (
            <button
              key={i}
              className={`py-3 px-5 text-sm lg:text-lg font-bold text-center rounded-t-lg ${currentTab === tab && "bg-indigo-200"} grow`}
              onClick={() => setCurrentTab(tab)}
            >
              {tab}
            </button>
          ))}
        </div>
      </div>
      <div className="grid grid-cols-2 px-2 w-full bg-indigo-200 lg:px-10">
        <div
          className="flex overflow-auto relative flex-col gap-3 items-center py-5"
          style={{ maxHeight: "calc(100vh - 100px)" }}
        >
          {currentTab === "inventory devices" && <AddOrUpdateDeviceForm />}
          {currentTab === "inventory part details" && <AddPartsForm />}
        </div>
        <div
          className="overflow-auto relative gap-3 justify-center items-center pb-5 bg-indigo-200"
          style={{ maxHeight: "calc(100vh - 100px)" }}
        >
          <div className="px-5 mt-3 w-full">
            <InventoryList type={currentTab} />
          </div>
        </div>
      </div>
    </div>
  );
}
