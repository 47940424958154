import axios from "axios";
import { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { toast } from "react-hot-toast";
import { useUserContext } from "../context/UserContext";
import InventoryListItem from "./InventoryListItem";

const typeToSuffix = {
  "inventory devices": "get-all-products",
  "inventory parts": "get-all-inventory-parts",
  "inventory part details": "get-all-part-details",
};

export default function InventoryList({ type }) {
  const [items, setItems] = useState([]);
  const [expanded, setExpanded] = useState(null);
  const [loading, setLoading] = useState(false);
  const { token } = useUserContext();
  const routeSuffix = typeToSuffix[type];

  useEffect(() => {
    if (!token) return;
    setLoading(true);
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory/${routeSuffix}?pageSize=1000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        setItems(flatten(res.data.data.data));
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      } finally {
        setLoading(false);
      }
    })();
  }, [token, type, routeSuffix]);

  function flatten(data) {
    if (data.inventory_part) {
      data.inventory_part_id = data.inventory_part.part_id;
      data.part_name = data.inventory_part.part_name;
      data.model = data.inventory_part.model;
      delete data.inventory_part;
    }
    return data;
  }

  return (
    <div
      className={`space-y-2 ${loading ? "opacity-30 pointer-events-none" : ""}`}
    >
      <div className="flex justify-end">
        <CSVLink
          data={items}
          filename={`${type}.csv`}
          className="py-2 px-4 text-white rounded bg-sky-600 hover:bg-sky-700"
        >
          Download CSV
        </CSVLink>
      </div>
      {items.map((item, i) => (
        <InventoryListItem
          key={i}
          data={item}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
    </div>
  );
}
