import { IoMdClose } from "react-icons/io";

export default function InventoryListItem({ data, expanded, setExpanded }) {
  delete data.createdAt;
  delete data.updatedAt;
  return (
    <>
      <div
        className="relative py-4 px-5 w-full rounded cursor-pointer group bg-sky-100 hover:bg-sky-200"
        onClick={() => setExpanded(data.device_id)}
      >
        <p className="text-lg">Device ID: {data.device_id}</p>
        {expanded === data.device_id && (
          <>
            <IoMdClose
              className="absolute right-2 top-4 z-50 text-2xl"
              onClick={(e) => {
                e.stopPropagation();
                setExpanded(null);
              }}
            />
            <div className="grid grid-cols-2">
              {Object.keys(data).map((key) => (
                <p className="text-gray-800" key={key}>
                  <span className="text-black">{key}:</span> {data[key]}
                </p>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
}
