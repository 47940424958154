export function getCheckRequestTemplate(subscenario) {
  let template = {
    connectivity: {
      name: "connectivity",
      status: "running",
    },
    "flush start": {
      name: "flush start",
      status: "waiting",
    },
  };

  if (subscenario === "all_flush") {
    ["sed_flush", "carb_flush", "ro_flush", "alk_flush"].forEach((s) => {
      template[`${s} running`] = {
        name: `${s} running`,
        status: "waiting",
      };
      template[`${s} completed`] = {
        name: `${s} completed`,
        status: "waiting",
      };
    });
  } else {
    template[`${subscenario} running`] = {
      name: `${subscenario} running`,
      status: "waiting",
    };
    template[`${subscenario} completed`] = {
      name: `${subscenario} completed`,
      status: "waiting",
    };
  }
  template["check issues"] = {
    name: "check issues",
    status: "waiting",
  };
  template["notification triggered"] = {
    name: "notification triggered",
    status: "waiting",
  };
  template["notification received In-App"] = {
    name: "notification received In-App",
    comments: "Did you receive notification from the app ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  };
  template["notification received WhatsApp"] = {
    name: "notification received WhatsApp",
    comments: "Did you receive notification from WhatsApp ?",
    input: {
      clicked: false,
      dependency: "notification triggered",
    },
  };
  return template;
}
