import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserContext } from "../context/UserContext";
import DeviceIdInput from "./DeviceIdInput";
import InventoryListItem from "./InventoryListItem";

const columnNames = [
  "product_id",
  "model",
  "tr1",
  "fs1",
  "fs2",
  "fs3",
  "pr1",
  "pr2",
  "tds1",
  "tds2",
  "tds3",
  "hps",
  "leakagesensor",
  "ropump",
  "sv1",
  "sv2",
  "sv3",
  "sv4",
  "sv5",
  "sv6",
  "sediment_filter",
  "carbon_filter",
  "ro_membrane",
  "alkaline_filter",
  "uv_filter",
  "flowrestrictor",
  "wstorage",
  "switchbox",
  "pcb",
  "smps",
  "enclosure_pcb",
  "speaker",
  "body",
  "powerswitch_body",
  "uv_powersupply",
  "uv_tube",
  "tap",
];

export default function Inventory() {
  const [deviceId, setDeviceId] = useState("");
  const [inventoryData, setInventoryData] = useState();
  const [showForm, setShowForm] = useState(false);
  const [deviceExists, setDeviceExists] = useState(false);
  const [allProducts, setAllProducts] = useState([]);
  const [expandedItem, setExpandedItem] = useState();
  const [type, setType] = useState("");
  const { token } = useUserContext();

  useEffect(() => {
    if (!token) return;
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory/get-all-products?pageSize=1000`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        setAllProducts(res.data.data.data);
      } catch (e) {
        console.error(e);
        toast.error(e.response?.data?.message ?? e.message);
      }
    })();
  }, [token]);

  useEffect(() => {
    if (!token) return;
    setInventoryData();
    setShowForm(false);
    setDeviceExists(false);
    setType("");
    if (deviceId.length < 12) return;
    setDeviceId(deviceId.slice(0, 12));
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory/device-exists/${deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        if (res.data.data.inventory) {
          delete res.data.data.inventoryData.createdAt;
          delete res.data.data.inventoryData.updatedAt;
          delete res.data.data.inventoryData.device_id;
          setInventoryData(res.data.data.inventoryData);
          setShowForm(true);
          setType("update");
          setDeviceExists(true);
        } else if (res.data.data.productInfo) {
          toast.error("Device already left the inventory");
          setShowForm(false);
        } else {
          setShowForm(true);
          setType("add");
        }
      } catch (err) {
        console.error(err);
        toast.error(err.response?.data?.message ?? err.message);
      }
    })();
  }, [deviceId, token]);

  async function submitForm(e) {
    e.preventDefault();
    // add or update inventory
    const methodFunc = type === "add" ? axios.post : axios.patch;
    const data = { ...inventoryData, device_id: deviceId };
    delete data.product_id;
    try {
      let res = await methodFunc(
        `${process.env.REACT_APP_API_URL}/inventory/${type}-product${type === "update" ? `/${inventoryData.product_id}` : ""}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.status !== 200) throw new Error(res.data.message);
      toast.success("Inventory Updated successfully");
      setShowForm(false);
      setDeviceId("");
    } catch (e) {
      console.error(e);
      toast.error(e.response?.data?.message ?? e.message);
    }
  }

  return (
    <div className="grid grid-cols-2 px-10 pt-20 w-full">
      <div
        className="flex overflow-auto relative flex-col gap-3 items-center py-5"
        style={{ maxHeight: "calc(100vh - 100px)" }}
      >
        <div className="flex gap-3 justify-center items-center">
          <DeviceIdInput deviceId={deviceId} setDeviceId={setDeviceId} />
        </div>
        <form
          onSubmit={submitForm}
          className="grid grid-cols-2 py-5 px-10 my-5 space-y-1 w-fit"
        >
          <h2 className="col-span-2 mb-10 text-xl font-bold">
            {type === "add"
              ? "Add To Inventory"
              : type === "update" && "Update Device Inventory"}
          </h2>
          {showForm &&
            (deviceExists ? Object.keys(inventoryData) : columnNames).map(
              (col) => (
                <>
                  <label htmlFor={col} className="">
                    {col}
                  </label>
                  <input
                    type="text"
                    value={
                      col === "product_id" && !inventoryData?.product_id
                        ? "Auto Generated"
                        : (inventoryData?.[col] ?? "")
                    }
                    id={col}
                    className="p-2 px-4 w-max border-2 disabled:bg-slate-200"
                    required
                    disabled={col === "product_id"}
                    onChange={(e) => {
                      setInventoryData({
                        ...(inventoryData ?? {}),
                        [col]: e.target.value,
                      });
                    }}
                  />
                </>
              ),
            )}
          {showForm && (
            <div className="col-span-2 py-5">
              <button
                type="submit"
                className="p-3 w-full font-bold text-white bg-sky-400 hover:bg-sky-500 active:bg-sky-600"
              >
                {type === "add" ? "Add" : "Update"}
              </button>
            </div>
          )}
        </form>
      </div>
      <div
        className="overflow-auto relative gap-3 justify-center items-center py-5"
        style={{ maxHeight: "calc(100vh - 100px)" }}
      >
        <h2 className="mb-5 text-xl font-bold text-center">Inventory</h2>
        <div className="px-5 space-y-3 w-full">
          {allProducts.map((product) => (
            <InventoryListItem
              key={product.product_id}
              data={product}
              expanded={expandedItem}
              setExpanded={setExpandedItem}
            />
          ))}
        </div>
      </div>
    </div>
  );
}
