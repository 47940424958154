import { Toaster } from "react-hot-toast";
import { BrowserRouter, Routes, Route } from "react-router-dom";

import ControlPanel from "./components/testing/ControlPanel";
import HomePage from "./components/HomePage";
import Navbar from "./components/Navbar";
import UserContextProvider from "./context/UserContext";
import DeviceConfig from "./components/device/page";
import Login from "./components/Login";
import Debug from "./components/Debug";
import DebugContextProvider from "./context/DebugContext";
import { Helmet } from "react-helmet";
import Inventory from "./components/inventory";

function App() {
  return (
    <BrowserRouter>
      <UserContextProvider>
        <DebugContextProvider>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Integration Script</title>
          </Helmet>
          <div className="App">
            <Navbar />
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/login" element={<Login />} />
              <Route path="/testing" element={<ControlPanel />} />
              <Route path="/device" element={<DeviceConfig />} />
              <Route path="/debug" element={<Debug />} />
              <Route path="/inventory" element={<Inventory />} />
            </Routes>
          </div>
          <Toaster position="top-right" />
        </DebugContextProvider>
      </UserContextProvider>
    </BrowserRouter>
  );
}

export default App;
