export default function DeviceIdInput({ deviceId, setDeviceId }) {
  return (
    <div className="flex gap-3 justify-center items-center w-full">
      <span>Device ID: </span>
      <input
        type="text"
        placeholder="Device ID"
        className="p-2 px-4 border-2"
        value={deviceId}
        onChange={(e) => setDeviceId(e.target.value)}
        maxLength={12}
      />
    </div>
  );
}
