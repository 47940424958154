import { Button, Dropdown, Input, Select, Space } from "antd";
import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useUserContext } from "../../context/UserContext";

export default function AddPartsForm() {
  const { token } = useUserContext();
  const [partTypes, setPartTypes] = useState([]);
  const [selectedType, setSelectedType] = useState(null);
  const [isPcbSelected, setIsPcbSelected] = useState(false);
  const [quantity, setQuantity] = useState(0);

  useEffect(() => {
    if (!token) return;
    (async () => {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/get-all-inventory-parts`,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      const data = res.data.data.data.map((part) => ({
        value: part.part_id,
        label: part.part_name + " - " + part.part_type,
      }));
      setPartTypes(data);
      setSelectedType(data[0]);
    })();
  }, [token]);

  useEffect(() => {
    if (!selectedType) return;
    setIsPcbSelected(selectedType.label.split(" - ")[0] === "pcb");
  }, [selectedType]);

  async function submitForm(e) {
    e.preventDefault();
    if (selectedType.value === 0)
      return toast.error("Please select a part type");
    const data = {
      part_id: selectedType.value,
      quantity: e.target.quantity.value,
      pcb_id: e.target.pcbId?.value,
    };
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/inventory/add-part-details`,
        data,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      if (res.status !== 200) throw new Error(res.data.message);
      toast.success("Parts added successfully");
    } catch (e) {
      console.error(e);
      toast.error(e.response?.data?.message ?? e.message);
    }
  }

  return (
    <form onSubmit={submitForm} className="space-y-5">
      <h2 className="my-10 text-2xl">Add part to inventory</h2>
      <div className="space-y-3">
        <label htmlFor="partType">Part Type</label>
        <Select
          options={partTypes}
          defaultValue={{ value: 0, label: "Select Part Type" }}
          className="w-full"
          id="partType"
          onSelect={(e) =>
            setSelectedType(partTypes.filter((p) => p.value === e)[0])
          }
        />{" "}
      </div>
      <div className="space-y-3">
        <label htmlFor="quantity">Quantity</label>
        <Input
          type="number"
          id="quantity"
          name="quantity"
          value={isPcbSelected ? 1 : quantity}
          disabled={isPcbSelected}
          className="disabled:text-gray-700 disabled:bg-gray-100"
          placeholder="e.g., 5"
          onChange={(e) => setQuantity(e.target.value)}
        />
      </div>
      {isPcbSelected && (
        <div className="space-y-3">
          <label htmlFor="pcbId">PCB ID</label>
          <Input id="pcbId" name="pcbId" placeholder="e.g., F02880CD31E8" />
        </div>
      )}
      <Button className="bg-indigo-400" type="primary" htmlType="submit">
        Add Parts
      </Button>
    </form>
  );
}
