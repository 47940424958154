import axios from "axios";
import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { FaCheck, FaTimes } from "react-icons/fa";
import { useUserContext } from "../../context/UserContext";
import DeviceIdInput from "../DeviceIdInput";

const columnNames = [
  "product_id",
  "model",
  "tr1",
  "fs1",
  "fs2",
  "fs3",
  "pr1",
  "pr2",
  "tds1",
  "tds2",
  "tds3",
  "hps",
  "leakagesensor",
  "ropump",
  "sv1",
  "sv2",
  "sv3",
  "sv4",
  "sv5",
  "sv6",
  "sediment_filter",
  "carbon_filter",
  "ro_membrane",
  "alkaline_filter",
  "uv_filter",
  "flowrestrictor",
  "wstorage",
  "switchbox",
  "pcb",
  "smps",
  "enclosure_pcb",
  "speaker",
  "body",
  "powerswitch_body",
  "uv_powersupply",
  "uv_tube",
  "tap",
];

export default function AddOrUpdateDeviceForm({ onSubmitCallback }) {
  const [deviceId, setDeviceId] = useState("");
  const [inventoryData, setInventoryData] = useState();
  const [type, setType] = useState("");
  const [showForm, setShowForm] = useState(false);
  const [deviceExists, setDeviceExists] = useState(false);
  const [existingData, setExistingData] = useState();
  const [checkExists, setCheckExists] = useState({});
  const { token } = useUserContext();

  useEffect(() => {
    if (!token) return;
    setInventoryData();
    setShowForm(false);
    setDeviceExists(false);
    setType("");
    if (deviceId.length < 12) return;
    setDeviceId(deviceId.slice(0, 12));
    (async () => {
      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API_URL}/inventory/device-exists/${deviceId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          },
        );
        if (res.status !== 200) throw new Error(res.data.message);
        if (res.data.data.inventory) {
          delete res.data.data.inventoryData.createdAt;
          delete res.data.data.inventoryData.updatedAt;
          delete res.data.data.inventoryData.device_id;
          setInventoryData(res.data.data.inventoryData);
          setExistingData(res.data.data.inventoryData);
          setShowForm(true);
          setType("update");
          setCheckExists(() => {
            const obj = {};
            Object.keys(res.data.data.inventoryData).forEach((name) => {
              obj[name] = true;
            });
            return obj;
          });
          setDeviceExists(true);
        } else if (res.data.data.productInfo) {
          toast.error("Device already left the inventory");
          setShowForm(false);
        } else {
          setShowForm(true);
          setType("add");
        }
      } catch (err) {
        console.error(err);
        toast.error(err.response?.data?.message ?? err.message);
      }
    })();
  }, [deviceId, token]);

  async function submitForm(e) {
    e.preventDefault();
    const methodFunc = type === "add" ? axios.post : axios.patch;
    const data = { ...inventoryData, device_id: deviceId };
    delete data.product_id;
    for (const key in data) {
      if (key === "product_id" || key === "device_id") continue;
      if (!data[key] || data[key] === "")
        return toast.error(`Please enter ${key}`);
      if (checkExists[key] === null || checkExists[key] === undefined)
        return toast.error(`Please "check" if ${key} is valid`);
      if (checkExists[key] === false)
        return toast.error(`Wrong ${key} entered`);
    }
    try {
      let res = await methodFunc(
        `${process.env.REACT_APP_API_URL}/inventory/${type}-product${type === "update" ? `/${inventoryData.product_id}` : ""}`,
        data,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        },
      );
      if (res.status !== 200) throw new Error(res.data.message);
      toast.success("Inventory Updated successfully");
      setShowForm(false);
      setDeviceId("");
      if (typeof onSubmitCallback === "function") onSubmitCallback();
    } catch (e) {
      console.error(e);
      toast.error(e.response?.data?.message ?? e.message);
    }
  }

  async function checkPart(name) {
    if (!inventoryData?.[name])
      return setCheckExists({ ...checkExists, [name]: false });
    const partId = inventoryData[name];
    try {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/inventory/part-exists/${partId}`,
        { headers: { Authorization: `Bearer ${token}` } },
      );
      if (res.status !== 200) throw new Error(res.data.message);
      setCheckExists({ ...checkExists, [name]: res.data.data.usageState });
    } catch (e) {
      console.error(e);
      toast.error(e.response?.data?.message ?? e.message);
    }
  }

  return (
    <>
      <div className="flex gap-3 justify-center items-center">
        <DeviceIdInput deviceId={deviceId} setDeviceId={setDeviceId} />
      </div>
      <form
        onSubmit={submitForm}
        className="gap-y-2 py-5 my-5 lg:grid lg:grid-cols-3 lg:px-10 w-fit"
      >
        <h2 className="col-span-3 mb-10 text-xl font-bold">
          {type === "add"
            ? "Add To Inventory"
            : type === "update" && "Update Device Inventory"}
        </h2>
        {showForm &&
          (deviceExists ? Object.keys(inventoryData) : columnNames).map(
            (col) => (
              <>
                <label htmlFor={col} className="block mt-3" title={col}>
                  {col}
                </label>
                <div className="flex col-span-2 gap-2 items-center">
                  <input
                    type="text"
                    value={
                      col === "product_id" && !inventoryData?.product_id
                        ? "Auto Generated"
                        : (inventoryData?.[col] ?? "")
                    }
                    id={col}
                    className="p-2 px-4 w-max border-2 disabled:bg-slate-200"
                    required
                    disabled={col === "product_id"}
                    onChange={(e) => {
                      setInventoryData({
                        ...(inventoryData ?? {}),
                        [col]: e.target.value,
                      });
                      if (
                        existingData &&
                        e.target.value === existingData?.[col]
                      )
                        setCheckExists({ ...checkExists, [col]: true });
                      else setCheckExists({ ...checkExists, [col]: null });
                    }}
                  />
                  {col !== "product_id" &&
                    col !== "model" &&
                    (checkExists[col] === null ||
                    checkExists[col] === undefined ? (
                      <button
                        type="button"
                        onClick={() => checkPart(col)}
                        className="py-1 px-2 ml-1 h-full rounded-lg bg-sky-400"
                      >
                        Check
                      </button>
                    ) : checkExists[col] === true ? (
                      <FaCheck className="ml-2 text-2xl text-green-500" />
                    ) : (
                      <FaTimes className="ml-2 text-2xl text-red-500" />
                    ))}
                </div>
              </>
            ),
          )}
        {showForm && (
          <div className="col-span-3 py-5">
            <button
              type="submit"
              className="p-3 w-full font-bold text-white bg-sky-400 hover:bg-sky-500 active:bg-sky-600"
            >
              {type === "add" ? "Add" : "Update"}
            </button>
          </div>
        )}
      </form>
    </>
  );
}
