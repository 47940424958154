const types = {
  default_on: "default_on",
  emergency: "emergency",
  master: "master",
  check_request: "check_request",
  reset_wifi: "reset_wifi",
  termination: "termination",
  dnd: "dnd",
  reboot: "reboot",
  pulse_calibration: "pulse_calibration",
};
export default types;
